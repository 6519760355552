
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import Sidebar from "@/components/left-sidebar.vue"; // @ is an alias to /src
import Loader from "@/components/loader.vue"; // @ is an alias to /src 
import AssetsServices from '@/services/assets-services'
import PopUp from "@/components/PopUp.vue";
import utils from "@/utils/utils";




@Component({
    components: {
        Sidebar,
        Loader,
        PopUp,
    },
})
export default class AssetClassSidebar extends Vue {
    @Prop({ type: Object, default: () => { } }) updatePayload!: any;
    @Prop({ type: Object, default: () => [] }) options!: any;

    @Prop({ default: false }) forUpdate!: boolean;
    @Prop({ type: Function, default: () => { } }) retrieve!: Function;

    payload: any = {}
    loading = false;
    // For Modal
    modalShow = false;
    modalMessage: string[] = [];
    modalSimpleMessage: string = "";
    modalType = "";
    saveData = true;
    error: any = "";
    userId = 0;


    closeModal() {
        this.modalShow = false;
        this.modalMessage = [];
        this.modalSimpleMessage = "";
        this.modalType = "";
    }

    showModal(message: string, array: string[], type: "success" | "error" | "errorArray",) {
        this.modalShow = true;
        this.modalMessage = array;
        this.modalType = type;
        this.modalSimpleMessage = message
    }
    save() {
        const localUser = localStorage.getItem("user");
        if (localUser !== null) {
            const userObject = JSON.parse(localUser);
            this.userId = userObject.id;
        }
        this.payload['created_by'] = this.userId
        this.error = ""
        const error_list = utils.getEmptyKeys(this.payload, ['title', 'depreciation_type', 'precent'])
        if (error_list.length > 0) {
            this.error = utils.generateErrorMessage(error_list);
        }
        else {
            if (!this.forUpdate) {
                AssetsServices.createAssetClass(this.payload).then((response) => {
                    if (response.status == 201) {
                        this.showModal("Created Successfully", [], "success");
                        this.payload = {};
                        this.retrieve();
                        this.error = "";
                        this.showModal("Successfully Created", [], "success");
                    }
                    else {
                        this.showModal("", response.data, "errorArray");
                    }
                }).catch((err) => {
                    console.log('Unexpected error:', err);
                    this.showModal('An unexpected error occurred', [], "error");
                })
            } else {
                AssetsServices.updateAssetClass(this.payload.id, this.payload,).then((response) => {
                    if (response.status == 200) {
                        this.showModal("Updated Successfully", [], "success");
                        this.payload = {};
                        this.retrieve();
                        this.error = "";
                    }
                    else {
                        this.showModal("", response.data, "errorArray");
                    }
                }).catch((err) => {
                    console.log('Unexpected error:', err);
                    this.showModal('An unexpected error occurred', [], "error");
                })
            }

        }
    }
    @Watch("updatePayload")
    onModalShowChanged(newValue: boolean, oldValue: boolean) {
        this.payload = this.updatePayload;
    }

}
